// src/App.jsx

'use client';

import { Carousel } from 'flowbite-react';
import React from 'react';
import Slider from "../English/Slider"
/* This example requires Tailwind CSS v2.0+ */
const features = [
    {
      name: 'منتجات',
      description:
        'بإستخدام اجود نوع و اعلی مواصفات  من  مواد الخام المستخدمة و احدث الأجهزة وخطوط الإنتاج وإعتماد تقنيات انتاجية متقدمة ثَبُت فوائدها علمياً، “معمل بلوک سوران” إستطاع تحويل مواد بناء إعتيادية الى “منتجات إستثنائية متخصصة” وذلك وفقاً لإعتماد أُسلوب وآلية التصنيع المبينة في فقرة “سياسة الإنتاج”',
    },
  
  ]
function Main() {
  return (
    <div className="App">
  
  <div className="bg-white">
      <div aria-hidden="true" className="relative">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/soran-block-factory-410411.appspot.com/o/image_about_us%2FDSC01935%20copy%202.jpg?alt=media&token=ff4641f1-fddd-4255-83fc-d602f7be03f1.jpg"
          alt=""
          className="w-full  rounded-md h-96 object-center object-cover"
        />
      </div>

      <div className="relative -mt-12 max-w-7xl mx-auto pb-16 px-4 sm:pb-24 sm:px-6 lg:px-8">
        <div className="max-w-2xl mx-auto text-center lg:max-w-4xl mt-20">
          <h2 className="text-3xl font-extrabold tracking-tight  sm:text-4xl bg-brown text-white text-medium rounded-sm">نبذة عنا
</h2>
          <p dir="rtl" className="mt-4 p-2 text-white bg-brownhover rounded-sm text-justify   ">
          التجمع الفكري لمجموعة أشخاص من عدة احترافات ذات خلفيات هندسية/ تجارية/ تقنية/ صناعية، جمعت سنوات عديدة من الخبرة لتستغل الفرص للمشاركة في إشراقة الفجر العراقي الجديد المتمثل في عملية إعادة الإعمار والبناء، وللمساهمة في تحقيق الرفاه الإجتماعي وإسراع عجلة التنمية الإقتصادية من خلال تجهيزه بمواد أساسية للإعمار والبناء ذات نوعية وجودة عالية. الإستثمار اللامتناهي في احدث التقنيات وفي اذكى وأرفع مستوى من المعدات والخطوط الإنتاجية، اضافةً الى التركيز على أهمية بناء كادرعمل متفاني من المؤهلين و ذوي الخبرة والتدريب العالي، مكَن “معمل بلوک سوران” من تقديم منتجات ذات جودة عالية (على الدوام) بأعلى المواصفات لتفي بالمعايير المنصوص عليها من قبل قوانين البناء الوطنية والدولية.
          </p>
          <p dir="rtl" className="mt-4 p-2 text-white bg-brownhover  rounded-sm text-justify">
“معمل بلوک سوران” هي منشأة صناعية لإنتاج البلوک العازل الحراري و الخفیف  وزن ، التي تُستعمل في المراحل الإبتدائية في عالم الإعمار والبناء ولكنها ذات حاجة وأهمية عاليتين عندما تفي بالمواصفات المطلوبة في عملية بناء الأمم.
          </p>
        </div>
        <div className="max-w-2xl mx-auto  py-0 px-4 sm:py-16 sm:px-6 lg:max-w-7xl lg:px-8">
        <div className="grid items-center grid-cols-1 gap-y-16 gap-x-8 lg:grid-cols-2">
          <div>
            <div className="border-b border-gray-200 pb-4">
              <h2  dir="rtl" className="  sm:text-4xl bg-brown text-white text-medium rounded-sm">منتجات</h2>
              <p c dir="rtl" className="mt-4 p-2 text-white bg-brownhover  rounded-sm text-justify   ">
              بلوک عازل الحراري و خفیف الوزن (بونزا) بانواع احجام و اشکال المختلفة
              </p>
            </div>

            <dl className="mt-4 space-y-10   " dir="rtl">
              {features.map((feature) => (
                <div key={feature.name}>
                  <dd className="text-smmt-4 p-2 text-white bg-brownhover  rounded-sm text-justify">{feature.description}</dd>
                </div>
              ))}
            </dl>
          </div>

          <div>
            <div className="aspect-w-1 aspect-h-1 rounded-lg bg-gray-100 overflow-hidden">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/soran-block-factory-410411.appspot.com/o/productionpolicy%2F2.jpg?alt=media&token=b30ec971-33e4-42f5-bc78-aedd8328e3b4.jpg"
                alt="Black kettle with long pour spot and angled body on marble counter next to coffee mug and pour-over system."
                className="w-full h-full object-center object-cover"
              />
            </div>
            <div className="grid grid-cols-2 gap-4 mt-4 sm:gap-6 sm:mt-6 lg:gap-8 lg:mt-8">
              <div className="aspect-w-1 aspect-h-1 rounded-lg bg-gray-100 overflow-hidden">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/soran-block-factory-410411.appspot.com/o/productionpolicy%2F8.jpg?alt=media&token=391a66ec-6045-4c2e-9367-aa270aff1616.jpg"
                  alt="Detail of temperature setting button on kettle bass with digital degree readout."
                  className="w-full h-full object-center object-cover"
                />
              </div>
              <div className="aspect-w-1 text-2xl aspect-h-1 rounded-lg bg-gray-100 overflow-hidden">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/soran-block-factory-410411.appspot.com/o/productionpolicy%2F7.jpg?alt=media&token=47454ad2-4743-412a-8a5e-59ad12e3848d.jpg"
                  alt="Kettle spout pouring boiling water into coffee grounds in pour-over mug."
                  className="w-full h-full object-center object-cover"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
        
      </div>
    </div>
  
  

    </div>
  );
}

export default Main;

