import logo from './logo.svg';
import { Fragment, useState } from 'react'

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import HomeEn from "./Commponents/English/Home"
import MainEn from "./Commponents/English/Main"

import HomeAr from "./Commponents/Arabic/Home"
import MainAr from "./Commponents/Arabic/Main"
import AboutusAr from "./Commponents/Arabic/aboutus"
import ProductionpolicyAr from "./Commponents/Arabic/ProductionPolicy"
import PageNotFound from "./PageNotFound"
import ContactusAr from "./Commponents/Arabic/Contactus"
import BrochureAr from './Commponents/Arabic/brochure'
import ProductsAr from "./Commponents/Arabic/products"
import ProductsViewAr from "./Commponents/Arabic/productsview"
import GalleriesAr from "./Commponents/Arabic/Galleries"

function App() {
 
 
  return (
  

      <div className="app">
        
      
 
    <Routes>

    {['/en', '/en/'].map((path) => (
            <Route path={path} element={<HomeEn />} />
  ))}
      
      <Route path='MainEn' element={<MainEn />}>
                    <Route path='MainEn' element={<MainEn />} /> {/*A nested route!*/}
                </Route> 


                {['/', '/ar'].map((path) => (
            <Route path={path} element={<HomeAr />} />
            
  ))}
      
      <Route path='ar/' element={<HomeAr />}>
                    <Route path='home' element={<MainAr />} /> {/*A nested route!*/}
                    <Route path='aboutus' element={<AboutusAr />} /> {/*A nested route!*/}
                    <Route path='productionpolicy' element={<ProductionpolicyAr />} /> {/*A nested route!*/}
                    <Route path='contactus' element={<ContactusAr />} /> {/*A nested route!*/}
                    <Route path='products' element={<ProductsAr />} />
                    <Route path='view/:id' element={<ProductsViewAr />} />
                    <Route path='galleries' element={<GalleriesAr />} />
                    <Route path='galleries/:id' element={<GalleriesAr />} />
                    <Route path='brochure' element={<BrochureAr />} />



                </Route> 


                <Route path='/' element={<HomeAr />}>
                
        <Route path='home' element={<MainAr />} /> {/*A nested route!*/}
        <Route path='aboutus' element={<AboutusAr />} /> {/*A nested route!*/}
        <Route path='productionpolicy' element={<ProductionpolicyAr />} />
        <Route path='contactus' element={<ContactusAr />} />
        <Route path='products' element={<ProductsAr />} />
        <Route path='view/:id' element={<ProductsViewAr />} />
        <Route path='galleries' element={<GalleriesAr />} />
     
        <Route path='galleries/:id' element={<GalleriesAr />} />
        <Route path='brochure' element={<BrochureAr />} />
    </Route> 
  
    </Routes>



    
  
  
    <footer class="footer footer-center p-4 bg-base-300  text-center">
  <aside>
    <p>Copyright © 2024 - All right reserved by  <a href='https://www.facebook.com/Roottgroup/' className='   text-lime-400'> Root Group</a> </p>
  </aside>
</footer>
      
      </div>
  
 


  );
}

export default App;
