// src/App.jsx

'use client';

import { Carousel } from 'flowbite-react';
import React from 'react';
import Slider from "../English/Slider"
function Main() {
  return (
    <div className="App">
  

  
   <Slider/>

    </div>
  );
}

export default Main;

