/* This example requires Tailwind CSS v2.0+ */
import { MailIcon, PhoneIcon } from '@heroicons/react/solid'

import Fireproof from "../../image/icons/Fire proof.png"
import Lightwhight from "../../image/icons/Lightwhight.png"
import Soundproof from "../../image/icons/Sound proof.png"


import { useState, useEffect } from "react";
import { getDatabase, ref as databaseRef, child, get } from "firebase/database";
import firebaseApp from '../../firebase';
const people = [
  {
    name: 'Jane Cooper',
    title: 'Paradigm Representative',
    role: 'Admin',
    email: 'janecooper@example.com',
    telephone: '+1-202-555-0170',
    fireproof:Fireproof,
    lightwhight:Lightwhight,
    soundproof:Soundproof,
    imageUrl:
      'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
  },
  {
    name: 'Jane Cooper',
    title: 'Paradigm Representative',
    role: 'Admin',
    email: 'janecooper@example.com',
    telephone: '+1-202-555-0170',
    imageUrl:
      'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
  },
  
  // More people...
]

export default function Example() {


    const [products, setproducts] = useState([]);
  
    useEffect(() => {
        const dbRef = databaseRef(firebaseApp);
        
        get(child(dbRef, "Products")).then((snapshot) => {
         
          
            {setproducts("")}
          
            if (snapshot.exists()) {
            
               
           snapshot.forEach(snap => {
               // snap.val() is the dictionary with all your keys/values from the 'students-list' path
             
               setproducts(arr => [...arr, snap.val()]); 
              
             
           });}
           
         
        }).catch((error) => {
          console.error(error);
        });
    
        // Don't forget to detach the event listener when the component unmounts
  
      }, []);
  

      
  return (
    <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        {products.map((p) => (
              <div key={p.id} >
             
              
    
        <li
        
          className="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200"
        >
          <div className="flex-1 flex flex-col p-8">
            <img className="w-32 h-32 flex-shrink-0 mx-auto " src={p.src} alt="" />
            <h3 className="mt-6 text-gray-900 text-sm font-medium">{p.id}</h3>
            <dl className="mt-1 flex-grow flex flex-col justify-between">
              <dt className="sr-only">Title</dt>
              <dd className="text-gray-500 text-sm">{p.dim}</dd>
              <dt className="sr-only">Role</dt>
            
            </dl>
          </div>
          <div>
            <div className="-mt-px flex divide-x divide-gray-200">
              <div className="w-0 flex-1 flex">
                <a

                  className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
                >
                  <img className="w-4 h-6  text-gray-400" aria-hidden="true" src={Fireproof} />

                </a>
              </div>
              <div className="-ml-px w-0 flex-1 flex">
                <a
             
                  className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500"
                >
                                   <img className="w-4 h-6 text-gray-400" aria-hidden="true" src={Soundproof} />
   </a>
              </div>
              <div className="-ml-px w-0 flex-1 flex">
                <a
                  className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500"
                >
                                   <img className="w-4 h-6  text-gray-400" aria-hidden="true" src={Lightwhight} />
  </a>
              </div>
            </div>
          </div>
        </li>
   

  
</div>
            ))}

    </ul>

  )
}


