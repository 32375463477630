import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Import carousel styles
import "./css/slider.css"
import Image1 from "./CoverImage/1.jpg"
import Image2 from "./CoverImage/2.jpg"
import Image3 from "./CoverImage/3.jpg"
import Image4 from "./CoverImage/4.jpg"
const CarouselComponent = () => {
  return (
    <Carousel
      showArrows={true}
      infiniteLoop={true}
      showThumbs={false}
      showStatus={false}
      autoPlay={true}
      interval={3000} 
      className='mt-2'
      // Set the interval for auto sliding
    >

     
<div>
      <img src={Image1} alt="Image 2" />
      </div>
      <div>
      <img src={Image2} alt="Image 1" />
      </div>
     
      
      <div>
      <img src={Image3} alt="Image 1" />
      </div>
      <div>
      <img src={Image4} alt="Image 1" />
      </div>
    </Carousel>
  );
};

export default CarouselComponent;
