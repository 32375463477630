
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getStorage } from "firebase/storage";
import { getDatabase } from 'firebase/database';
import { getAnalytics } from "firebase/analytics";
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDM7YHsfOhdTiN7C4CexvLQ6Ukr_mRqL4g",
  authDomain: "soran-block-factory-410411.firebaseapp.com",
  databaseURL: "https://soran-block-factory-410411-default-rtdb.firebaseio.com",
  projectId: "soran-block-factory-410411",
  storageBucket: "soran-block-factory-410411.appspot.com",
  messagingSenderId: "874619398065",
  appId: "1:874619398065:web:298a9005c4886e3c9c3f90"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const database = getDatabase(app);
export const storage = getStorage(app);
const analytics = getAnalytics(app);
export default database
