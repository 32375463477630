
import React from "react";
import { useParams } from 'react-router-dom';
import { useState, useEffect } from "react";
import { ShieldCheckIcon, XIcon } from '@heroicons/react/outline'
import { getDatabase, ref as databaseRef, child, get } from "firebase/database";
import firebaseApp from '../../firebase';


  


  

export default function Example() {
  const [showModal, setShowModal] = React.useState(false);
  const [url, SetUrl] = React.useState("");
  const [products, setproducts] = useState([]);
  useEffect(() => {
    const dbRef = databaseRef(firebaseApp);
     
    get(child(dbRef, "Album/")).then((snapshot) => {
     
      
        {setproducts("")}
      
        if (snapshot.exists()) {
        
           
       snapshot.forEach(snap => {
           // snap.val() is the dictionary with all your keys/values from the 'students-list' path
         
           setproducts(arr => [...arr, snap.val()]); 
          
         
       });}
       
     
    }).catch((error) => {
      console.error(error);
    });

    // Don't forget to detach the event listener when the component unmounts

  }, []);


 


 

  // Don't forget to detach the event listener when the component unmounts






  return (
    <div>
                   <ul role="list" className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8 ">
                  
       {products.map((p) => (
              <div key={p.id} >
             
          
         <li  className="relative ">
          
    
          <img class="w-full  h-60 object-cover "src={p.src} alt="Sunset in the mountains"/>
 


        </li>
     
   
    
      
   

  
</div>
            ))} </ul>
   

    </div>
  )
}
