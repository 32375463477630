
const products = [
    {
      id: 1,
      name: 'Basic Tee',
      href: '#',
      imageSrc: 'https://firebasestorage.googleapis.com/v0/b/soran-block-factory-410411.appspot.com/o/pdf%2FArtboard%201.png?alt=media&token=dd0a607d-91c4-4a83-b0cc-b44361e44d11',
      imageAlt: "Front of men's Basic Tee in black.",
      price: '$35',
      color: 'Black',
    },
    // More products...
  ]
  
  export default function Example() {
    return (
      <div className="bg-white">
        <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8 ">
          <h2 className="text-2xl font-bold tracking-tight text-gray-900 float-right text-right">كتيبات المعمل والمنتجات</h2>
  
         <div className="flex float-right mt-9 ">
         {products.map((product) => (
              <div key={product.id} className="å   relative">
                <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-md  lg:aspect-none group-hover:opacity-75 lg:h-80">
                
                <a  href="https://firebasestorage.googleapis.com/v0/b/soran-block-factory-410411.appspot.com/o/pdf%2Ffinal%20cat.pdf?alt=media&token=d209f442-b1ab-4803-b7a9-75e88cff24c8.pdf"   className="  text-brown">
                         <img
                    src={product.imageSrc}
                    alt={product.imageAlt}
                    className="h-full w-full object-cover object-center lg:h-full lg:w-full"
                  />
                  </a>
                </div>
                <div className="mt-4 ">
                  <div>
                    <h3  dir="rtl" className="text-sm text-gray-700 ">
                      <a  href="https://firebasestorage.googleapis.com/v0/b/soran-block-factory-410411.appspot.com/o/pdf%2Ffinal%20cat.pdf?alt=media&token=d209f442-b1ab-4803-b7a9-75e88cff24c8.pdf"   className="  text-brown">
                       
                        انقر هنا لعرض وتحميل الكتيب
                      </a>
                    </h3>
                  </div>
                </div>
              </div>
            ))}
         </div>
        </div>
      </div>
    )
  }
  