
import { Fragment } from 'react'
import { Dialog, RadioGroup, Transition } from '@headlessui/react'
import { ShieldCheckIcon, XIcon } from '@heroicons/react/outline'
import { CheckIcon, QuestionMarkCircleIcon, StarIcon } from '@heroicons/react/solid'

import { useState, useEffect } from "react";
import { getDatabase, ref as databaseRef, child, get } from "firebase/database";
import firebaseApp from '../../firebase';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

const product = {
  name: 'Everyday Ruck Snack',
  price: '$220',
  rating: 3.9,
  href: '#',
  imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-quick-preview-03-detail.jpg',
  imageAlt: 'Interior of light green canvas bag with padded laptop sleeve and internal organization pouch.',
  sizes: [
    { name: '18L', description: 'Perfect for a reasonable amount of snacks.' },
    { name: '20L', description: 'Enough room for a serious amount of snacks.' },
  ],
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Example() {
  const [open, setOpen] = useState(true)
  const [selectedSize, setSelectedSize] = useState(product.sizes[0])

  const [cid, setcid] = useState(useParams())
    const [products, setproducts] = useState([]);
  useEffect(() => {
  const dbRef = databaseRef(firebaseApp);
  if (cid === null || cid === undefined) {
    // Handle the case when the parameter is null or undefined
    return <div>Parameter is null or undefined</div>;
  }else{
    get(child(dbRef, "Products/",{cid})).then((snapshot) => {
    {setproducts("")}
    if (snapshot.exists()) {
          
      snapshot.forEach(snap => {
        
          if(snap.val().id===cid.id){
            setproducts(arr => [...arr, snap.val()]); 

          } 
       
         
        
      });
  
    }
  
     
   
  }).catch((error) => {
    console.error(error);
  });

  // Don't forget to detach the event listener when the component unmounts
  }
}, []);

 


  return(
    
    <div  >
      {products.map((p) => (
    <div  key={p.id}className="flex min-h-screen text-center md:block md:px-2 lg:px-4" style={{ fontSize: 0 }}>
       

       {/* This element is to trick the browser into centering the modal contents. */}
   

         <div className="flex text-base text-left transform transition w-full md:inline-block md:max-w-2xl md:px-4 md:my-8 md:align-middle lg:max-w-4xl">
           <div className="w-full relative flex items-center bg-white shadow divide divide-gray-200 px-4 pt-14 pb-8 overflow-hidden  sm:px-6 sm:pt-8 md:p-6 lg:p-8">
             <a
               type="button"
               className="absolute top-4 right-4 text-gray-400 hover:text-gray-500 sm:top-8 sm:right-6 md:top-6 md:right-6 lg:top-8 lg:right-8"
               href='/products'
             >
               <span className="sr-only">Close</span>
               <XIcon className="h-6 w-6" aria-hidden="true" />
             </a>

             <div className="w-full grid grid-cols-1 gap-y-8 gap-x-6 items-start sm:grid-cols-12 lg:gap-x-8">
               <div className="sm:col-span-4 lg:col-span-5">
                 <div className="aspect-w-1 p-3 aspect-h-1 rounded-lg bg-gray-100 overflow-hidden" >
                   <img src={p.src}  className="  relative  object-cover" />
                 </div>
                 <p className="absolute top-4 left-4 text-center sm:static sm:mt-6">
                   <a href={product.href} className="font-medium text-indigo-600 hover:text-indigo-500">
                     {p.id}
                   </a>
                 </p>
               </div>
               <div className="sm:col-span-8 lg:col-span-7">
                 <h2 className="text-2xl font-extrabold text-gray-900 sm:pr-12"></h2>

              

                 <section aria-labelledby="options-heading" className="mt-6">
                   <h3 id="options-heading" className="sr-only">
                     Product options
                   </h3>

                   <form>
                     <div className="sm:flex sm:justify-between">
                       {/* Size selector */}
                       <RadioGroup value={selectedSize} onChange={setSelectedSize}>
                         <RadioGroup.Label className="block text-sm font-medium text-gray-700">
                           Size
                         </RadioGroup.Label>
                         <div className="mt-1 grid p-2 grid-cols-1 gap-4 sm:grid-cols-2  border border-gray-300 rounded-lg">
                        
                            {p.dim}
                            
                         </div>
                       </RadioGroup>
                     </div>
                     <div className="mt-4 flex">
                       <a href="#" className="group flex text-sm text-gray-500 hover:text-gray-700">
                         <span>What size should I buy?</span>
                         <QuestionMarkCircleIcon
                           className="flex-shrink-0 ml-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                           aria-hidden="true"
                         />
                       </a>
                     </div>
                     <div className="mt-6">
                       <button
                         type="submit"
                         className="w-full bg-brown border border-transparent rounded-md py-3 px-8 flex items-center justify-center text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-indigo-500"
                       >
                         Add to bag
                       </button>
                     </div>
                  
                   </form>
                 </section>
               </div>
             </div>
           </div>
         </div>
     </div>
      ))}
  </div>
   
  )


}
