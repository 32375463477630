
import { MailIcon, PhoneIcon } from '@heroicons/react/outline'
import React, { useState } from 'react';
import emailjs from 'emailjs-com';
export default function Example() {
 
  
  const [emailData, setEmailData] = useState({
    to_name:"",
    toEmail: "",
    phonenumber:"",
    subject: "",
    message: ""
  });
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEmailData({ ...emailData, [name]: value });
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.send('service_4t09frh', 'template_o1bqup8', emailData, '_XohBdoCxttMZq_vg')
      .then((response) => {
        console.log('Email sent successfully:', response);
      })
      .catch((error) => {
        console.error('Error sending email:', error);
      });
      
  };
  return (
    <div className="bg-gray-100">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <div className="relative bg-white ">
          <h2 className="sr-only">Contact us</h2>

          <div className="grid grid-cols-1 lg:grid-cols-3">
            {/* Contact information */}
            <div  className="relative overflow-hidden py-10 px-6 bg-brown sm:px-10 xl:p-12">
              <div className="absolute inset-0 pointer-events-none sm:hidden" aria-hidden="true">
                <svg
                  className="absolute inset-0 w-full h-full"
                  width={343}
                  height={388}
                  viewBox="0 0 343 388"
                  fill="none"
                  preserveAspectRatio="xMidYMid slice"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M-99 461.107L608.107-246l707.103 707.107-707.103 707.103L-99 461.107z"
                    fill="url(#linear1)"
                    fillOpacity=".1"
                  />
                  <defs>
                    <linearGradient
                      id="linear1"
                      x1="254.553"
                      y1="107.554"
                      x2="961.66"
                      y2="814.66"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#fff" />
                      <stop offset={1} stopColor="#fff" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <div
                className="hidden absolute top-0 right-0 bottom-0 w-1/2 pointer-events-none sm:block lg:hidden"
                aria-hidden="true"
              >
                <svg
                  className="absolute inset-0 w-full h-full"
                  width={359}
                  height={339}
                  viewBox="0 0 359 339"
                  fill="none"
                  preserveAspectRatio="xMidYMid slice"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M-161 382.107L546.107-325l707.103 707.107-707.103 707.103L-161 382.107z"
                    fill="url(#linear2)"
                    fillOpacity=".1"
                  />
                  <defs>
                    <linearGradient
                      id="linear2"
                      x1="192.553"
                      y1="28.553"
                      x2="899.66"
                      y2="735.66"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#fff" />
                      <stop offset={1} stopColor="#fff" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <div
                className="hidden absolute top-0 right-0 bottom-0 w-1/2 pointer-events-none lg:block"
                aria-hidden="true"
              >
                <svg
                  className="absolute inset-0 w-full h-full"
                  width={160}
                  height={678}
                  viewBox="0 0 160 678"
                  fill="none"
                  preserveAspectRatio="xMidYMid slice"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M-161 679.107L546.107-28l707.103 707.107-707.103 707.103L-161 679.107z"
                    fill="url(#linear3)"
                    fillOpacity=".1"
                  />
                  <defs>
                    <linearGradient
                      id="linear3"
                      x1="192.553"
                      y1="325.553"
                      x2="899.66"
                      y2="1032.66"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#fff" />
                      <stop offset={1} stopColor="#fff" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <h3 dir="rtl" className="text-lg font-medium text-white ">معلومات الاتصال
</h3>
              <p dir="rtl"  className="mt-6 text-base text-indigo-50 max-w-3xl">
              هل لديك سؤال، تعليق، أو تريد فقط أن تقول مرحبا؟ املأ النموذج أدناه وسنقوم بالرد عليك!
              </p>
              <dl className="mt-8 space-y-6">
                <dt>
                  <span className="sr-only">Phone number</span>
                </dt>
                <dd className="flex text-base text-white">
                  <PhoneIcon className="flex-shrink-0 w-6  mt-3h-6 text-white" aria-hidden="true" />
                  <span className="ml-3"> 0750 236 1177 <br/>0770 236 1177 <br/> 0786 293 2022 </span>

                  
                </dd>
                <dt>
                  <span className="sr-only">Email</span>
                </dt>
                <dd className="flex text-base text-white">
                  <MailIcon className="flex-shrink-0 w-6 h-6 text-white" aria-hidden="true" />
                  <span className="ml-3">info@soranblockfactory.com</span>
                </dd>
              </dl>
             
            </div>

            {/* Contact form */}
            <div className="py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12">
              <h3 dir="rtl" className="text-lg font-medium text-gray-900">أرسل لنا رسالة</h3>
              <form dir="rtl"   className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8" method='POST' onSubmit={sendEmail}>
                <div>
                  <label htmlFor="first-name" className="block text-sm font-medium text-right text-gray-900">
                  الاسم الكامل
                  </label>
                  <div className="mt-1">
                    <input
                   
                   type="text" name="to_name" 

                      value={emailData.to_name}
                    onChange={handleInputChange}
           
                      className="py-3 px-4 block w-full  border text-gray focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                
                <div>
                  <label dir="rtl"  htmlFor="email" className="block text-sm font-medium text-gray-900 text-right">
                  بريد إلكتروني
                  </label>
                  <div className="mt-1">
                    <input 
                    id='email'
                  type="email" name="toEmail"
                   value={emailData.toEmail}
                    onChange={handleInputChange}
                     
                      className="py-3 px-4 block w-full border text-gray-900 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div>
                  <div className="flex justify-between">
                    <label htmlFor="phone" className="block text-sm font-medium text-gray-900">
                    هاتف
                    </label>
                    
                  </div>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="phonenumber"
                   
                      value={emailData.phonenumber}
                    onChange={handleInputChange}
                     
               
                      className="py-3 px-4 block w-full border   text-gray-900 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                      aria-describedby="phone-optional"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label htmlFor="subject" className="block text-right text-sm font-medium text-gray-900">
                  موضوع
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="subject"
                      id="subject"
                      value={emailData.subject} 
                      onChange={handleInputChange} 
                      className="py-3 px-4 block w-full border  border-gray-300 text-gray-900 focus:ring-indigo-500 focus:border-indigo-500  rounded-md"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <div className="flex justify-between">
                    <label htmlFor="message" className="block text-sm font-medium text-gray-900">
                    رسالة
                    </label>
                    <span id="message-max" className="text-sm text-gray-500">
                    الأعلى. 500 حرف
                    </span>
                  </div>
                  <div className="mt-1">
                    <textarea
                      id="message"
                      name="message"
                      rows={4}
                      className="py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-indigo-500 focus:border-indigo-500 border border-gray-300 rounded-md"
                      aria-describedby="message-max"
                     
                      value={emailData.message} 
                      onChange={handleInputChange} å

                    />
                  </div>
                </div>
                <div className="sm:col-span-2 sm:flex sm:justify-end">
                  <button
                    type="submit"
                    className="mt-2 w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-brown hover:bg-brown-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:w-auto"
                  >
                    ارسال
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


