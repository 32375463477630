
import { Fragment, useState } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import React, { useEffect } from 'react';
import Main from "./Main";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Logo from "./logo.svg"
import LogoLand from "./logolan.svg"
import AboutUs  from "./aboutus"
import ProductionPolicy from "./ProductionPolicy"
import ContactusAr from "./Contactus"
import Brochure from './brochure'
import Products from './products'
import ProductsView from './productsview'
import Galleries from './Galleries'
import {
 
  HomeIcon,
  ShieldExclamationIcon,
  UserGroupIcon,
  ChartSquareBarIcon,
  DocumentIcon,
  PhotographIcon,
  ChatIcon,
  XIcon,
  MenuAlt2Icon,BellIcon,
  BanIcon
} from '@heroicons/react/outline'
import { SearchIcon } from '@heroicons/react/solid'
import { Chat, Globe } from 'heroicons-react'

const navigation = [
  { name: 'الصفحة الرئيسية', href: '/home', icon: HomeIcon, current: false },
  { name: 'نبذة عنا', href: '/aboutus', icon: UserGroupIcon, current: false },
  { name: 'سياسة الإنتاج', href: '/productionpolicy', icon: ShieldExclamationIcon, current: false },
  { name: 'المنتجات', href: '/products', icon: ChartSquareBarIcon, current: false },
  { name: 'معرض الصور', href: '/galleries', icon: PhotographIcon, current: false },
  { name: '	كتيبات المعمل والمنتجات', href: '/brochure', icon: DocumentIcon, current: false },
  { name: 'تواصل معنا', href: '/contactus', icon: ChatIcon, current: false },
  // { name: 'English', href: '/en', icon: Globe, current: false },
  // { name: 'کوردی', href: '#', icon: Globe, current: false },

]
const userNavigation = [
  { name: 'Your Profile', href: '#' },
  { name: 'Settings', href: '#' },
  { name: 'Sign out', href: '#' },
]


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Example() {
  const [sidebarOpen, setSidebarOpen] = useState(false)

  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-gray-100">
        <body class="h-full">
        ```
      */}
      <div className=' '>
      <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="fixed inset-0 flex z-40 md:hidden  flex-row-reverse " onClose={setSidebarOpen}>
          <Transition.Child
               as={Fragment}
               enter="transition-opacity ease-linear duration-300"
               enterFrom="opacity-0"
               enterTo="opacity-100"
               leave="transition-opacity ease-linear duration-300"
               leaveFrom="opacity-100"
               leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>
            <Transition.Child
               as={Fragment}
               enter=""
               enterFrom="-translate-x-full"
               enterTo="translate-x-0"
               leave=""
            >
              <div className=" relative   flex flex-col max-w-xs w-full  pb-4 bg-brown">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute  ">
                    <button
                      type="button"
                      className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only" >Close sidebar</span>
                      <XIcon  className="h-6 w-6 text-brown" aria-hidden="true"   onClick={() => setSidebarOpen(false)} />
                    </button>
                  </div>
                </Transition.Child>
                <div className="f bg-white h-48">
                  <img
                    className=" w-96 "
                    src={LogoLand}
                  />
                </div>
                <div className=" flex-1 h-0 overflow-y-auto bg-brown  ">
                  <nav className="px-2 space-y-1 py-4">
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className={classNames(
                          item.current
                            ? 'bg-brownhover text-white'
                            : 'text-white hover:bg-brownhover hover:text-white',
                          'group flex items-center flex-row-reverse px-2 py-2 text-base font-medium rounded-md group hover:brownhover'
                        )}
                      >
                        <item.icon
                          className={classNames(
                            item.current ? 'text-gray-100' :  'text-white group-hover:text-white ',
                            'mr-4 flex-shrink-0 h-6 w-6'
                          )}
                          aria-hidden="true"
                        />
                      <p className=' mr-3'>  {item.name}</p>
                      </a>
                    ))}
                  </nav>
                  <div className='mt-8 text-center'>
                <p className="flex space-x-4 text-center ml-32 ">
      <FaFacebook className="text-white " />
      <FaInstagram className="text-white" />
      <FaLinkedin className="text-white" />
    </p>
    <p className='text-sm mt-2 text-center text-white '>
    © Copyright 2026, All Rights Reserved

    </p>
              </div>
                </div>
              </div>
            </Transition.Child>
            <div className="flex-shrink-0 w-14" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </Dialog>
        </Transition.Root>


        {/* Static sidebar for desktop */}
  
        <div className='fixed top-20 right-0 bg-brown  '>
<div className="hidden md:flex md:w-64 md:flex-col  md:inset-y-0  ">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex flex-col flex-grow border-r  border-gray-200  bg-brown overflow-y-auto">
            <div className=" bg-white ">
              <img
                className=" w-96 mt-4 "
                src={Logo}
              />
            </div>
            <div className="flex-grow flex flex-col py-3 mt-0 ">
              <nav className="flex-1  px-2 pb-4 space-y-1 bg-brown  py-3  ">
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    
                    className={classNames(
                      item.current ? 'bg-brownhover text-white' : 'text-white hover:bg-brownhover hover:text-white',
                      'group flex flex-row-reverse  px-2 py-2 text-sm font-medium rounded-md '
                    )}
                  >
                   
                   
                    <item.icon
                      className={classNames(
                        item.current ? '' : 'text-gray-100 group-hover:text-white',
                        'mr-3 flex-shrink-0 h-6 w-6'
                      )}
                      aria-hidden="true"
                    />
                     <p className='mr-2'>
                     {item.name}
                     </p>
        
                  </a>
                  
                ))}
                  <div className='mtext-center '>
                <p className="flex space-x-4 text-center ml-20 mt-16 ">

      <a href='https://www.facebook.com/people/Soran-block-factory/61553519533919/?mibextid=LQQJ4d'>   <FaFacebook className="text-white " /></a>
      <FaInstagram className="text-white" />
      
    </p>
    {/* <p className='text-sm mt-2 text-center text-white '>
    © Copyright 2026, All Rights Reserved

    </p> */}
    
              </div>
              </nav>
            
            </div>
            
          </div>
        </div>
          </div>

        
        <div className=" flex flex-col flex-1 ">
          <div className=" flex h-16 bg-white shadow  ">
           
            <div className="flex-1 px-4 md:flex">
              
              <div className="ml-4 md:flex md:items-center md:ml-6  text-right">
              

                {/* Profile dropdown */}
                <Menu as="div" className=" text-right ">
                 
                 <div className=''>
                 <h3 className=' font-medium  text-left  mt-4 md:mt-0 absolute md:relative '>
                  Soran Block Factory
                  
                 </h3>
                
                
                 </div>
                 <button
              type="button"
              className="px-4    py-5    text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <MenuAlt2Icon className="h-6 w-6 " aria-hidden="true" />
            </button>
          
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {userNavigation.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <a
                              href={item.href}
                              className={classNames(
                                active ? 'bg-gray-200' : '',
                                'block px-4 py-2 text-sm text-gray-100'
                              )}
                            >
                            
                              {item.name}
                            </a>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

        

          <main className="flex-1 sm:mr-0 md:mr-60  ">
            <div className="py-6 ">
              <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
              </div>
              <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                {/* Replace with your content */}
                <div className="py-4   ">
                  
                <Routes>

                <Route path="/" element={  <Main/>   }> </Route>

<Route path="home" element={  <Main/>   }> </Route>
<Route path="aboutus" element={  <AboutUs/>   }>  </Route>
ContactusAr
<Route path="productionpolicy" element={  <ProductionPolicy/>   }>

    </Route>
    <Route path="contactus" element={  <ContactusAr/>   }>

</Route>
Products
<Route path="brochure" element={  <Brochure/>   }>

</Route>
<Route path="products" element={  <Products/>   }>

</Route>
<Route path="view/:id" exact element={  <ProductsView/>   }>

</Route>
<Route path="galleries" element={  <Galleries/>   }>

</Route>
<Route path="galleries/:id" element={  <Galleries/>   }>

</Route>

</Routes>
                </div>
                {/* /End replace */}
              </div>
            </div>
          </main>
        </div>
        
      </div>
    </>
  )

  
}
